import React from "react"
import { Link as GaLink } from "gatsby"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import Layout from "../components/layout"
import Head from "../components/head"
import agreementSvg from "../assets/agreement.svg"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(15, 0, 12),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(9, 0, 4),
    },
  },
  heroGridContainer: {
    display: "grid",
    gridGap: theme.spacing(6),
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  heroImage: {
    width: "100%",
  },
  heroGridItemImage: {
    textAlign: "center",
  },
  heroGridItemContent: {},
  whiteBg: {
    backgroundColor: theme.palette.background.paper,
  },
  heroButtons: {
    marginTop: theme.spacing(5),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },

  girdContainer: {
    display: "grid",
    gridGap: theme.spacing(4),
    gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    marginTop: theme.spacing(3),
  },
  cardGridContainer: {
    display: "grid",
    gridGap: theme.spacing(2),
    gridTemplateColumns: "100px 1fr",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "80px 1fr",
    },
  },
}))

const IndexPage = () => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Layout>
      <Head title="Home" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <div className={classes.heroGridContainer}>
              <div className={classes.heroGridItemContent}>
                <Typography
                  component="h1"
                  variant="h2"
                  align={matches ? "left" : "center"}
                  color="textPrimary"
                  gutterBottom
                >
                  Legal Experts
                </Typography>
                <Typography
                  variant="h5"
                  align={matches ? "left" : "center"}
                  color="textSecondary"
                  paragraph
                >
                  For immigration, conveyancing, family matters, and corporate
                  matters.
                </Typography>
                <div className={classes.heroButtons}>
                  <Grid
                    container
                    spacing={2}
                    justify={matches ? "flex-start" : "center"}
                  >
                    <Grid item>
                      <Button
                        component={React.forwardRef((props, ref) => (
                          <GaLink innerRef={ref} {...props} />
                        ))}
                        variant="contained"
                        color="secondary"
                        to="/contact"
                      >
                        Get In Touch
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className={classes.heroGridItemImage}>
                <img
                  src={agreementSvg}
                  alt="Mylan cut through communications"
                  className={classes.heroImage}
                />
              </div>
            </div>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage
